.checkboxes-list{

  max-width: 100%;

  > div{
    width: 100%;
    margin-right: 0;
    margin-bottom: 0;
    &:last-child{
      margin-bottom: 0;
    }
  }

  .label{
    width: 100%;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  label.custom-control-label{
    width: 100%;
    cursor: pointer;
    padding-top: 4px ;
    padding-bottom: 4px;

    &:before,
    &:after{
      top: .3125rem;
    }

    .value{
      width: 100%;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;

      .icon{
        display: block;
        float: left;
        margin-right: 8px;
        height: 1rem;
      }

      .name{
        display: block;
        float: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        ~ .count {
          margin-left: 0.3em;
        }
      }

      .count{
        display: block;
        float: left;
        color: var(--gray);
      }
    }
  }
}
